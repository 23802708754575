import {
  archiveOrderID,
  Box,
  ButtonGroup,
  type CustomerOrder,
  Divider,
  Flex,
  MixpanelEvent,
  modifyOrder,
  resubmitOrderForm,
  selectArchivedOrderIDSet,
  ToggleButton,
  useMixpanel,
  useObservableValue,
  useStaticSubscription,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWLOrderServiceContext,
  useWLOrdersProvider,
  wsScanToMap,
} from '@talos/kyoko';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { map } from 'rxjs';
import { ActiveOrdersRequest } from '../../tokens/order';
import { ActiveOrdersList } from './ActiveOrdersList';
import { RecentOrdersList } from './RecentOrdersList';
export const OrderCards = () => {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { recentOrders: recentOrdersObs } = useWLOrdersProvider();
  const archivedOrderIDs = useWLOrderFormSelector(selectArchivedOrderIDSet);
  const recentOrders = useObservableValue(
    () => recentOrdersObs.pipe(map(orders => sortBy(orders, '-SubmitTime'))),
    [recentOrdersObs]
  );

  const { data: activeOrdersSub } = useStaticSubscription<CustomerOrder>(ActiveOrdersRequest);

  const activeOrders = useObservableValue(
    () =>
      activeOrdersSub.pipe(
        wsScanToMap({
          getUniqueKey: o => o.OrderID,
          newMapEachUpdate: false,
        }),
        map(map => [...map.values()]),
        map(orders => sortBy(orders, '-SubmitTime'))
      ),
    [activeOrdersSub]
  );

  const unarchivedActiveOrders = useMemo(() => {
    return activeOrders?.filter(order => !archivedOrderIDs.has(order.OrderID));
  }, [activeOrders, archivedOrderIDs]);

  const dispatch = useWLOrderFormDispatch();
  const { orderService } = useWLOrderServiceContext();

  const handleArchiveOrder = useCallback(
    (orderID: string) => {
      dispatch(archiveOrderID(orderID));
      mixpanel.track(MixpanelEvent.ArchiveOrder);
    },
    [dispatch, mixpanel]
  );

  const handleResubmitOrder = useCallback(
    (order: CustomerOrder) => {
      dispatch(resubmitOrderForm(order));
      history.push('/trade');
      mixpanel.track(MixpanelEvent.OpenResubmitOrder);
    },
    [dispatch, history, mixpanel]
  );

  const handleModifyOrder = useCallback(
    (order: CustomerOrder) => {
      dispatch(modifyOrder(order));
      history.push('/trade');
      mixpanel.track(MixpanelEvent.ModifyOrder);
    },
    [dispatch, history, mixpanel]
  );

  const handleCancelOrder = useCallback(
    (orderID: string) => {
      orderService.cancelOrder(orderID);
      mixpanel.track(MixpanelEvent.CancelOrder);
    },
    [orderService, mixpanel]
  );

  const [selectedTab, setSelectedTab] = useState<'Active' | 'Recent'>('Active');

  return (
    <Flex h="100%" flexDirection="column" w="100%">
      <ButtonGroup p="spacingSmall" gap="spacingSmall">
        <ToggleButton selected={selectedTab === 'Active'} onClick={() => setSelectedTab('Active')}>
          Active
        </ToggleButton>
        <ToggleButton selected={selectedTab === 'Recent'} onClick={() => setSelectedTab('Recent')}>
          Recent
        </ToggleButton>
      </ButtonGroup>
      <Divider orientation="horizontal" color="gray.000" />
      <Box background="backgroundBody" w="100%" h="100%">
        {selectedTab === 'Active' && (
          <ActiveOrdersList
            handleCancelOrder={handleCancelOrder}
            handleModifyOrder={handleModifyOrder}
            handleResubmitOrder={handleResubmitOrder}
            handleArchiveOrder={handleArchiveOrder}
            activeOrders={unarchivedActiveOrders}
          />
        )}
        {selectedTab === 'Recent' && (
          <RecentOrdersList
            handleCancelOrder={handleCancelOrder}
            handleModifyOrder={handleModifyOrder}
            handleResubmitOrder={handleResubmitOrder}
            recentOrders={recentOrders}
          />
        )}
      </Box>
    </Flex>
  );
};
