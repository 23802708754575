import { darken, lighten } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#1B1B19',

    '000': '#040404',
    '010': '#131312',
    '020': '#232321',
    '030': '#3A3A39',
    '040': '#41413E',
    '050': '#4F4F4C',
    '060': '#50504E',
    '070': '#55554F',
    '080': '#828279',
    '090': '#A4A499',
    '100': '#FFFFEE',
  },

  primary: {
    mute: setAlpha(0.1, '#184B87'),
    dim: setAlpha(0.4, '#184B87'),
    default: '#184B87',
    lighten: lighten(0.1, '#3C85DD'),
  },

  green: {
    mute: setAlpha(0.1, '#00D64A'),
    dim: setAlpha(0.4, '#00D64A'),
    default: '#00D64A',
    lighten: lighten(0.1, '#00D64A'),
  },

  red: {
    mute: setAlpha(0.1, '#993131'),
    dim: setAlpha(0.4, '#993131'),
    default: '#993131',
    lighten: '#EC5B5B',
  },

  yellow: {
    mute: setAlpha(0.1, '#F2D264'),
    dim: setAlpha(0.4, '#F2D264'),
    default: '#F2D264',
    lighten: lighten(0.1, '#F2D264'),
  },

  blue: {
    mute: setAlpha(0.1, '#4AA4FF'),
    dim: setAlpha(0.4, '#4AA4FF'),
    default: '#4AA4FF',
    lighten: lighten(0.1, '#4AA4FF'),
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkWintermute2Theme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkWM2',

  // fontFamily: "'Manrope', sans-serif",
  // fontUrl:
  //   'https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundBody: colors.gray['000'],

  backgroundHeader: colors.gray.main,
  borderColorHeader: 'transparent',
  backgroundContent: colors.gray.main,

  backgroundChart: 'rgba(0, 0, 0, 0)',

  ...getInputStylesAppliedToSelect(DarkTheme),
  backgroundInput: colors.gray['020'],
  backgroundSelect: colors.gray['020'],
  backgroundSelectHover: colors.gray['020'],
  backgroundSelectFocus: colors.gray['020'],
  backgroundSelectInvalid: colors.gray['020'],
  backgroundSelectDisabled: colors.gray['020'],
  backgroundSelectReadOnly: colors.gray['020'],

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.5, colors.black.default),

  backgroundVolumeLadderBidSpread: setAlpha(0.6, colors.blue.lighten),
  backgroundVolumeLadderOfferSpread: setAlpha(0.6, colors.blue.lighten),

  backgroundToggle: colors.gray['000'],

  chamferMultiplier: 1,
  borderRadiusContent: 12,
  buttonGroupSharedBackground: darken(0.01, colors.gray['000']),
  backgroundMutedButton: 'transparent',
  colorTextMutedButton: colors.gray['090'],
});
