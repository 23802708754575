import type { Observable } from 'rxjs';
import { useHistoricalPrices } from '../../../hooks';
import type { MarketDataSnapshot, Security } from '../../../types';
import { FormGroup } from '../../DateTimeDurationPicker/styles';
import { Text } from '../../Text';
import { MarketView } from '../../VolumeLadder/MarketView';
import type { ClickRowCallback } from '../../VolumeLadder/types';

const resolution = '1H';

export function OrderFormMarketView({
  marketDataSnapshots,
  onClickRow,
  security,
}: {
  marketDataSnapshots: Observable<MarketDataSnapshot>;
  onClickRow: ClickRowCallback;
  security?: Security;
}) {
  const sparklineDataObservable = useHistoricalPrices({
    clearOnSymbolChange: true,
    symbol: security?.Symbol,
    resolution,
  });

  return (
    <FormGroup style={{ position: 'relative', margin: '0 0 16px' }}>
      <MarketView
        marketDataObservable={marketDataSnapshots}
        onClickRow={onClickRow}
        resolution={resolution}
        security={security}
        sparklineDataObservable={sparklineDataObservable}
      />
      <Text style={{ position: 'absolute', top: 0, right: 0, padding: 8 }}>{resolution}</Text>
    </FormGroup>
  );
}
