import { useCallback, useMemo, useState } from 'react';
import type { BaseField, FieldData, SelectorField } from '../../../fields';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { useIntl } from '../../../hooks/useIntl';
import {
  OrderFormSides,
  ParameterTypeEnum,
  PresenceEnum,
  type MarketDataSnapshot,
  type ParameterLike,
  type Security,
} from '../../../types';

import Big from 'big.js';
import { keys } from 'lodash';
import { defineMessages } from 'react-intl';
import type { Observable } from 'rxjs';
import { getIntlKey, type IntlWithFormatter } from '../../../contexts/IntlContext';
import { useGetCustomerMarketAccountLabel } from '../../../hooks';
import { strategyTranslations, useGetStrategyLikeLabel } from '../../../hooks/useGetStrategyLikeLabel';
import { useWLEffectiveMaxBalance } from '../../../hooks/useWLEffectiveMaxBalance';
import { WL_INITIATE_ORDER, WL_INITIATE_RFQ, useWLRoleAuth } from '../../../hooks/useWLRoleAuth';
import { orderSliceMessages } from '../../../providers/WLOrderForm/state/OrderSlice/messages';
import { useWLOrgConfigContext } from '../../../providers/WLOrgConfigProvider';
import { abbreviateId, format, isCFD } from '../../../utils';
import { Button, ButtonGroup, ButtonVariants } from '../../Button';
import { Box, HStack } from '../../Core';
import { CustomerMarketAccountSelector } from '../../CustomerMarketAccountSelector';
import { DateTimePicker } from '../../DateTimePicker';
import { CollapsibleFormSection, ManagedCollapsibleFormSection } from '../../Form/CollapsibleFormSection';
import { FormGroup } from '../../Form/FormGroup';
import { NumberInput } from '../../Form/NumberInput';
import { SearchSelect } from '../../Form/SearchSelect';
import { FormSection, Label, OrderFormHeader } from '../../Form/styles';
import { FormControlSizes } from '../../Form/types';
import { FormattedMessage } from '../../Intl';
import { SlideSelector } from '../../SlideSelector';
import { SymbolSelector, SymbolSelectorAppearance } from '../../SymbolSelector';
import { Text } from '../../Text';
import { AmountInput } from '../AmountInput';
import { CustomerBalanceDetailsHeader } from '../CustomerBalanceDetails';
import { MaxAvailable } from '../FormBalances';
import { IntervalInput } from '../IntervalInput';
import { PriceInput } from '../PriceInput';
import { QuantityInput } from '../QuantityInput';
import { FormFooter, FormWrapper, ScrollContainer } from '../styles';
import { OrderFormMarketView } from './OrderFormMarketView';
import { ModifyOrderID, ModifyWrapper } from './styles';
import type { NewOrderFormProps } from './types';

const messages = defineMessages({
  account: {
    defaultMessage: 'Account',
    id: 'Forms.NewOrderForm.account',
  },
  buy: {
    defaultMessage: 'Buy',
    id: 'Forms.NewOrderForm.buy',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'Forms.NewOrderForm.quantity',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'Forms.NewOrderForm.sell',
  },
  twoWay: {
    defaultMessage: 'Two-Way',
    id: 'Forms.NewOrderForm.twoWay',
  },
  sideCurrency: {
    defaultMessage: '{side, select, Buy {Buy} other {Sell}} {currency}',
    id: 'Forms.NewOrderForm.sideCurrency',
  },
  strategy: {
    defaultMessage: 'Strategy',
    id: 'Forms.NewOrderForm.strategy',
  },
  summary: {
    defaultMessage: 'Summary',
    id: 'Forms.NewOrderForm.summary',
  },
  updateOrder: {
    defaultMessage: 'Update Order',
    id: 'Forms.NewOrderForm.updateOrder',
  },
  getQuotes: {
    defaultMessage: 'Get {numSides, plural, one {Quote} other {Quotes}}',
    id: 'Forms.NewOrderForm.getQuotes',
  },
  order: {
    defaultMessage: 'Order',
    id: 'Forms.NewOrderForm.order',
  },
  modifyOrder: {
    defaultMessage: 'Modify Order',
    id: 'Forms.NewOrderForm.modifyOrder',
  },
  orderID: {
    defaultMessage: 'OrderID',
    id: 'Forms.NewOrderForm.orderID',
  },
  cancelQuote: {
    defaultMessage: 'Cancel',
    id: 'Forms.NewOrderForm.cancelQuote',
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'Forms.NewOrderForm.clear',
  },
  optional: {
    defaultMessage: 'Optional',
    id: 'Forms.NewOrderForm.optional',
  },
});
export const newOrderFormMessages = messages;

export const NewOrderForm = (props: NewOrderFormProps) => {
  const {
    balances,

    orderBeingModified,
    security,
    marketDataSnapshot,

    sideField,
    quantityField,
    orderCcyField,
    strategyField,
    marketAccountField,
    symbolField,

    parameterFields,
    handleStrategyChange,
    handleStrategyParamChange,
    handleQuantityChange,
    handleOrderCurrencyChange,
    handleSideChange,
    handleMarketAccountChange,

    handleClearForm,
    handleTouchAll,
    handleTouchField,
    handleFormSubmit: _handleFormSubmit,
    handleSymbolChange,

    balanceDetails,
    orderSummary,
    orderStep,
    quoteReqID,
    orderFormValid,
    orderViewType,
    handleViewTypeChange,
    pickQuoteView,

    isMobile,
  } = props;

  const { config } = useWLOrgConfigContext();
  const { isAuthorized } = useWLRoleAuth();
  const intl = useIntl();
  const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();

  const [balanceDetailsExpanded, setBalanceDetailsExpanded] = useState(false);

  const quoteBalance = symbolField.value ? balances.get(symbolField.value.QuoteCurrency) : undefined;
  const baseBalance = symbolField.value ? balances.get(symbolField.value.BaseCurrency) : undefined;

  const effectiveMaxBase = useWLEffectiveMaxBalance({
    account: marketAccountField.value?.SourceAccountID,
    security,
    side: sideField.value,
    currency: security?.BaseCurrency,
  });
  const effectiveMaxQuote = useWLEffectiveMaxBalance({
    account: marketAccountField.value?.SourceAccountID,
    security,
    side: sideField.value,
    currency: security?.QuoteCurrency,
  });
  const effectiveMaxBalance = useMemo(() => {
    switch (orderCcyField.value) {
      case security?.BaseCurrency:
        return effectiveMaxBase;
      case security?.QuoteCurrency:
        return effectiveMaxQuote;
      default:
        return undefined;
    }
  }, [effectiveMaxBase, effectiveMaxQuote, orderCcyField.value, security?.BaseCurrency, security?.QuoteCurrency]);

  const handleOnQuickOptionClicked = useDynamicCallback((percentage: number) => {
    // If clicking max, change currency and get max amt
    if (percentage === 1) {
      // buy
      if (sideField.value === OrderFormSides.Buy && symbolField.value?.QuoteCurrency) {
        handleOrderCurrencyChange(symbolField.value.QuoteCurrency);
        handleQuantityChange(
          format(effectiveMaxQuote || 0, {
            spec: security?.MinAmtIncrement,
            truncate: true,
            pretty: false,
            removeTrailingZeros: true,
          })
        );
        return;
      }
      // sell
      if (sideField.value === OrderFormSides.Sell && symbolField.value?.BaseCurrency) {
        handleOrderCurrencyChange(symbolField.value.BaseCurrency);
        handleQuantityChange(
          format(effectiveMaxBase || 0, {
            spec: security?.MinSizeIncrement,
            truncate: true,
            pretty: false,
            removeTrailingZeros: true,
          })
        );
        return;
      }
    }

    const isCounterCurrency = orderCcyField.value === security?.QuoteCurrency;

    handleQuantityChange(
      format(Big(percentage).mul(effectiveMaxBalance || 0), {
        spec: isCounterCurrency ? security?.MinPriceIncrement : security?.MinSizeIncrement,
        truncate: true,
        pretty: false,
        removeTrailingZeros: true,
      })
    );
  });

  const params = useMemo(() => {
    // Render all parameterFields that are visible.
    return keys(parameterFields)
      .filter(key => parameterFields[key].isVisible)
      .map(key => {
        const field = parameterFields[key];
        return (
          <FormParameterControl
            parameter={
              strategyField.value?.Parameters.find(p => p.Name === key) ??
              // For order modifications that don't have a strategy,
              // we need to create a dummy 'Limit Price' parameter.
              // The "field" for this already exists.
              (key === 'LimitPrice'
                ? ({
                    Name: key,
                    Description: '',
                    DisplayName: field.name,
                    Presence: PresenceEnum.Required,
                    Type: ParameterTypeEnum.Price,
                  } satisfies ParameterLike)
                : undefined)
            }
            key={key}
            field={field}
            security={security}
            handleChange={handleStrategyParamChange}
            orderCcy={orderCcyField.value}
            marketDataObs={marketDataSnapshot.marketDataSnapshots}
            data-testid={`oms-strategy-param-${key}`}
            intl={intl}
          />
        );
      });
  }, [
    strategyField.value?.Parameters,
    parameterFields,
    security,
    orderCcyField.value,
    marketDataSnapshot.marketDataSnapshots,
    intl,
    handleStrategyParamChange,
  ]);

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = useDynamicCallback(e => {
    e.preventDefault();
    _handleFormSubmit();
  });

  const curriedHandleTouchField = useCallback(
    (field: string) => {
      return () => handleTouchField(field);
    },
    [handleTouchField]
  );

  const getStrategyLikeLabel = useGetStrategyLikeLabel();

  return (
    <>
      <OrderFormHeader data-testid="new-order-form-header">
        <SymbolSelector
          appearance={SymbolSelectorAppearance.Ghost}
          onSymbolChanged={handleSymbolChange}
          dropdownWidth="200px"
          symbol={security?.Symbol}
          availableSecurities={symbolField.availableItems}
          disabled={symbolField.isDisabled}
          dropdownId="order-form-symbol-selector-dropdown"
          id="order-form-symbol-selector"
        />
        {orderBeingModified ? (
          <ModifyWrapper side={orderBeingModified.Side} data-testid="modify-order-header">
            <FormattedMessage {...messages.modifyOrder} />
            <ModifyOrderID>
              <FormattedMessage {...messages.orderID} />: <b>{abbreviateId(orderBeingModified.OrderID)}</b>
            </ModifyOrderID>
          </ModifyWrapper>
        ) : (
          <ButtonGroup shareBackground rounded style={{ marginLeft: 'auto' }} size={FormControlSizes.Small}>
            <>
              {isAuthorized(WL_INITIATE_ORDER) && (
                <Button
                  disabled={orderViewType.isDisabled}
                  onClick={() => handleViewTypeChange('order')}
                  data-testid="order-form-header-order-button"
                  variant={
                    orderViewType.value === 'order'
                      ? sideField.value === OrderFormSides.Buy
                        ? ButtonVariants.Positive
                        : ButtonVariants.Negative
                      : ButtonVariants.Muted
                  }
                >
                  <FormattedMessage {...messages.order} />
                </Button>
              )}
            </>

            <>
              {isAuthorized(WL_INITIATE_RFQ) && (
                <Button
                  disabled={orderViewType.isDisabled}
                  onClick={() => {
                    handleViewTypeChange('rfq');
                  }}
                  data-testid="order-form-header-rfq-button"
                  variant={
                    orderViewType.value === 'rfq'
                      ? sideField.value === OrderFormSides.Buy
                        ? ButtonVariants.Positive
                        : sideField.value === OrderFormSides.Sell
                        ? ButtonVariants.Negative
                        : ButtonVariants.Primary
                      : ButtonVariants.Muted
                  }
                >
                  {/* Reuse RFQ translation from strategy translations */}
                  <FormattedMessage {...strategyTranslations.rfq} />
                </Button>
              )}
            </>
          </ButtonGroup>
        )}
      </OrderFormHeader>

      <FormWrapper onSubmit={handleFormSubmit} autoComplete="off">
        <ScrollContainer>
          <FormSection>
            {isMobile && (
              <OrderFormMarketView
                marketDataSnapshots={marketDataSnapshot.marketDataSnapshots}
                onClickRow={args => {
                  handleSideChange(args.side as unknown as OrderFormSides);
                  handleStrategyParamChange('LimitPrice', args.price);
                }}
                security={security}
              />
            )}

            <FormGroup>
              <ButtonGroup prominent shareBackground borderRadius="borderRadiusButtonDefault">
                <Button
                  onClick={() => handleSideChange(OrderFormSides.Buy)}
                  disabled={sideField.isDisabled}
                  value={OrderFormSides.Buy}
                  variant={sideField.value === OrderFormSides.Buy ? ButtonVariants.Positive : ButtonVariants.Muted}
                  data-testid="order-form-header-buy-button"
                  prominent
                >
                  <FormattedMessage {...messages.buy} />
                </Button>

                {(config.enableTwoWayQuotes ?? true) && orderViewType.value === 'rfq' && (
                  <Button
                    disabled={sideField.isDisabled}
                    onClick={() => handleSideChange(OrderFormSides.Twoway)}
                    value={OrderFormSides.Twoway}
                    variant={sideField.value === OrderFormSides.Twoway ? ButtonVariants.Primary : ButtonVariants.Muted}
                    data-testid="order-form-header-two-way-button"
                    prominent
                  >
                    <FormattedMessage {...messages.twoWay} />
                  </Button>
                )}

                <Button
                  onClick={() => handleSideChange(OrderFormSides.Sell)}
                  disabled={sideField.isDisabled}
                  value={OrderFormSides.Sell}
                  variant={sideField.value === OrderFormSides.Sell ? ButtonVariants.Negative : ButtonVariants.Muted}
                  data-testid="order-form-header-sell-button"
                  prominent
                >
                  <FormattedMessage {...messages.sell} />
                </Button>
              </ButtonGroup>
            </FormGroup>
            {strategyField.isVisible && (
              <FormGroup
                disabled={!!orderBeingModified}
                error={strategyField.hasInvalidValue && strategyField.getTranslatedErrorString(intl)}
                controlId="Strategy"
                label={<FormattedMessage {...messages.strategy} />}
              >
                {isMobile ? (
                  <SlideSelector
                    getId={s => s.Name}
                    getLabel={getStrategyLikeLabel}
                    items={strategyField.availableItems}
                    selection={strategyField.value}
                    setSelection={handleStrategyChange}
                    disabled={strategyField.isDisabled}
                  />
                ) : (
                  <SearchSelect
                    selection={strategyField.value}
                    invalid={strategyField.hasInvalidValue}
                    touched={strategyField.isTouched}
                    disabled={!!orderBeingModified}
                    options={strategyField.availableItems}
                    getLabel={getStrategyLikeLabel}
                    onChange={handleStrategyChange}
                    data-testid="strategy-select"
                    id="Strategy"
                  />
                )}
              </FormGroup>
            )}

            {marketAccountField.isVisible && (
              <FormGroup
                disabled={marketAccountField.isDisabled}
                error={marketAccountField.hasInvalidValue && marketAccountField.getTranslatedErrorString(intl)}
                controlId="Account"
                label={<FormattedMessage {...messages.account} />}
              >
                {isMobile ? (
                  <SlideSelector
                    getId={p => p.SourceAccountID}
                    getLabel={p => p.DisplayName ?? p.SourceAccountID}
                    items={marketAccountField.availableItems}
                    selection={marketAccountField.value}
                    setSelection={handleMarketAccountChange}
                    disabled={marketAccountField.isDisabled}
                  />
                ) : (
                  <CustomerMarketAccountSelector
                    availableCustomerMarketAccounts={marketAccountField.availableItems}
                    onChangeMarketAccount={handleMarketAccountChange}
                    security={symbolField.value!}
                    touched={marketAccountField.isTouched}
                    accountID={marketAccountField.value?.SourceAccountID}
                    disabled={marketAccountField.isDisabled}
                    error={
                      marketAccountField.hasInvalidValue ? marketAccountField.getTranslatedErrorString(intl) : undefined
                    }
                  />
                )}
              </FormGroup>
            )}

            {quantityField.isVisible && (
              <FormGroup
                error={quantityField.hasInvalidValue && quantityField.getTranslatedErrorString(intl)}
                warning={quantityField.getTranslatedWarningString(intl)}
                controlId="OrderQty"
                labelWidth="100%"
                disabled={quantityField.isDisabled}
              >
                <HStack justifyContent="space-between">
                  <Label htmlFor="OrderQty">
                    <FormattedMessage {...messages.quantity} />{' '}
                  </Label>
                  {effectiveMaxBalance && security && !isCFD(security) && (
                    <MaxAvailable
                      maxBalance={effectiveMaxBalance}
                      security={security}
                      formCurrency={orderCcyField.value}
                      side={sideField.value}
                      marketAccountID={marketAccountField.value?.SourceAccountID}
                      limitPrice={parameterFields?.LimitPrice?.value}
                    />
                  )}
                </HStack>
                {!orderBeingModified ? (
                  <QuantityInput
                    side={sideField.value ?? OrderFormSides.Buy}
                    value={quantityField.displayValue ?? ''}
                    touched={quantityField.isTouched}
                    invalid={quantityField.hasInvalidValue}
                    currency={orderCcyField.value}
                    onChange={handleQuantityChange}
                    onCurrencyChange={handleOrderCurrencyChange}
                    onBlur={curriedHandleTouchField('OrderQty')}
                    security={security}
                    allowCurrencyChange
                    allowedCurrencies={(orderCcyField as SelectorField<string>).availableItems}
                    showQuickOptions={true}
                    disableQuickOptions={!effectiveMaxBalance}
                    onQuickOptionClicked={handleOnQuickOptionClicked}
                    min="0"
                    type="number"
                    name="OrderQty"
                    id="OrderQty"
                    disabled={quantityField.isDisabled}
                  />
                ) : (
                  <AmountInput
                    value={quantityField.displayValue ?? ''}
                    touched={quantityField.isTouched}
                    invalid={quantityField.hasInvalidValue}
                    currency={orderCcyField.value}
                    onChange={handleQuantityChange}
                    asset={security}
                    min="0"
                    type="number"
                    name="OrderQty"
                    id="OrderQty"
                    disabled={quantityField.isDisabled}
                  />
                )}
              </FormGroup>
            )}
            {orderViewType.value !== 'rfq' && <Box>{params}</Box>}
          </FormSection>
          {pickQuoteView}
        </ScrollContainer>
        {balanceDetails && marketAccountField.value && (
          <ManagedCollapsibleFormSection
            header={
              <CustomerBalanceDetailsHeader
                baseAmount={baseBalance?.AvailableAmount}
                isExpanded={balanceDetailsExpanded}
                marketAccount={getCustomerMarketAccountLabel(marketAccountField.value.SourceAccountID)}
                quoteAmount={quoteBalance?.AvailableAmount}
                security={security}
              />
            }
            isExpanded={balanceDetailsExpanded}
            onToggle={setBalanceDetailsExpanded}
          >
            {balanceDetails}
          </ManagedCollapsibleFormSection>
        )}
        {orderSummary && (
          <CollapsibleFormSection
            header={
              <Text color="colorTextAttention">
                <FormattedMessage {...messages.summary} />
              </Text>
            }
          >
            {orderSummary}
          </CollapsibleFormSection>
        )}

        <FormFooter>
          <Button data-testid="order-form-clear-button" onClick={handleClearForm}>
            {orderStep === 'rfqRequested' ? (
              <FormattedMessage {...messages.cancelQuote} />
            ) : (
              <FormattedMessage {...messages.clear} />
            )}
          </Button>
          <div onMouseEnter={handleTouchAll}>
            {orderViewType.value === 'order' ? (
              <Button
                disabled={!orderFormValid}
                loading={orderStep === 'loading'}
                variant={
                  sideField.value === OrderFormSides.Buy
                    ? ButtonVariants.Positive
                    : sideField.value === OrderFormSides.Sell
                    ? ButtonVariants.Negative
                    : undefined
                }
                data-testid="order-form-submit-button"
                type="submit"
                prominent
              >
                {orderBeingModified ? (
                  <FormattedMessage {...messages.updateOrder} />
                ) : (
                  <FormattedMessage
                    {...messages.sideCurrency}
                    values={{ side: sideField.value, currency: security?.BaseCurrency }}
                  />
                )}
              </Button>
            ) : orderViewType.value === 'rfq' ? (
              <Button
                disabled={!orderFormValid || !!quoteReqID}
                variant={getSideVariant(sideField.value)}
                type="submit"
                data-testid="order-form-submit-button"
                prominent
              >
                <FormattedMessage
                  {...messages.getQuotes}
                  values={{ numSides: sideField.value === OrderFormSides.Twoway ? 2 : 1 }}
                />
              </Button>
            ) : null}
            {}
          </div>
        </FormFooter>
      </FormWrapper>
    </>
  );
};

export const FormParameterControl = <T extends FieldData>({
  field,
  security,
  handleChange,
  orderCcy,
  parameter,
  marketDataObs,
  intl,
}: {
  security: Security | undefined;
  field: BaseField<T, any>;
  handleChange: (key: string, value: string | undefined) => void;
  parameter: ParameterLike | undefined;
  orderCcy?: string;
  marketDataObs: Observable<MarketDataSnapshot>;
  intl: IntlWithFormatter;
}) => {
  const onChange = useDynamicCallback((value: string | undefined) => {
    if (parameter) {
      handleChange(parameter.Name, value);
    }
  });

  if (!security || !parameter) {
    return null;
  }

  const fieldKey = parameter.Name;
  const placeholder = parameter.Presence === PresenceEnum.Optional ? intl.formatMessage(messages.optional) : undefined;
  const label =
    getIntlKey(parameter.Name) in orderSliceMessages
      ? intl.formatMessage(orderSliceMessages[getIntlKey(parameter.Name)])
      : parameter.DisplayName;

  return (
    <FormGroup
      color="colorTextDefault"
      error={field.hasInvalidValue ? field.getTranslatedErrorString(intl) : undefined}
      key={field.name}
      controlId={field.name}
      label={label}
      tooltip={parameter.Description}
      data-testid={`${fieldKey}-input-form-group`}
    >
      {(() => {
        switch (parameter.Type) {
          case ParameterTypeEnum.Price: {
            return (
              <PriceInput
                marketDataObs={marketDataObs}
                min="0"
                currency={security.QuoteCurrency}
                security={security}
                onChange={onChange}
                value={field.displayValue ?? ''}
                disabled={field.isDisabled}
                invalid={field.hasInvalidValue}
                touched={field.isTouched}
                placeholder={placeholder}
                id={fieldKey}
              />
            );
          }
          case ParameterTypeEnum.Qty: {
            return (
              <AmountInput
                onChange={onChange}
                asset={security}
                value={field.displayValue ?? ''}
                disabled={field.isDisabled}
                invalid={field.hasInvalidValue}
                min="0"
                currency={orderCcy}
                touched={field.isTouched}
                placeholder={placeholder}
                id={fieldKey}
              />
            );
          }
          case ParameterTypeEnum.Date: {
            return (
              <DateTimePicker
                value={field.value ? new Date(field.value) : null}
                onChange={d => onChange(d?.toUTCString())}
                disabled={field.isDisabled}
                touched={field.isTouched}
                placeholder={placeholder}
                invalid={field.hasInvalidValue}
                id={fieldKey}
              />
            );
          }
          case ParameterTypeEnum.Percent: {
            return (
              <NumberInput
                min="0"
                max="100"
                onChange={onChange}
                value={field.displayValue ?? ''}
                disabled={field.isDisabled}
                defaultIncrement="1"
                minIncrement="1"
                largeIncrement="10"
                invalid={field.hasInvalidValue}
                placeholder={placeholder}
                suffix="%"
                touched={field.isTouched}
                id={fieldKey}
              />
            );
          }
          case ParameterTypeEnum.Interval: {
            return (
              <IntervalInput
                onChange={onChange}
                value={field.displayValue ?? ''}
                disabled={field.isDisabled}
                invalid={field.hasInvalidValue}
                touched={field.isTouched}
                placeholder={placeholder}
                id={fieldKey}
              />
            );
          }

          default: {
            return (
              <Text id={fieldKey} color="colorTextImportant">
                {field.name}
              </Text>
            );
          }
        }
      })()}
    </FormGroup>
  );
};

function getSideVariant(side?: OrderFormSides | null): ButtonVariants {
  return side === OrderFormSides.Buy
    ? ButtonVariants.Positive
    : side === OrderFormSides.Sell
    ? ButtonVariants.Negative
    : ButtonVariants.Primary;
}
