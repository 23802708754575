import {
  Box,
  Icon,
  IndicatorDotVariants,
  IndicatorDotWrapper,
  VStack,
  isOrderComplete,
  useObservableValue,
  useWLOrdersProvider,
} from '@talos/kyoko';
import { NavLink } from 'react-router-dom';
import { map } from 'rxjs';
import styled from 'styled-components';
import type { LayoutComponentScreen } from '../../../../layouts/types';
import { MobileTabBarWrapper } from './styles';

interface MobileTabBarProps {
  items: LayoutComponentScreen[];
}
export const MobileTabBar = ({ items }: MobileTabBarProps) => {
  const { recentOrders } = useWLOrdersProvider();
  const incompleteOrders = useObservableValue(
    () => recentOrders.pipe(map(orders => orders.filter(o => !isOrderComplete(o.OrdStatus)))),
    [recentOrders],
    []
  );
  return (
    <MobileTabBarWrapper background="backgroundHeader" justifyContent="space-around">
      {items.map(item => (
        <TabBarLink to={item.path} key={item.path} exact={true}>
          <VStack
            w="4rem"
            p="spacingDefault"
            gap="spacingSmall"
            color="colorTextDefault"
            borderTop="solid 2px"
            borderColor="transparent"
          >
            <IndicatorDotWrapper
              show={item.label === 'Orders' && incompleteOrders.length > 0}
              variant={IndicatorDotVariants.Primary}
            >
              <Icon icon={item.icon} size={24} color="colorTextSubtle" />
            </IndicatorDotWrapper>
            <Box fontSize="fontSizeTiny" whiteSpace="nowrap">
              {item.label}
            </Box>
          </VStack>
        </TabBarLink>
      ))}
    </MobileTabBarWrapper>
  );
};

export const TabBarLink = styled(NavLink)`
  text-decoration: none;

  &.active {
    > div {
      border-color: ${({ theme }) => theme.colorTextImportant};
      color: ${({ theme }) => theme.colorTextImportant};
    }

    ${Icon} {
      color: ${({ theme }) => theme.colorTextImportant};
    }
  }
`;
