import { AccordionGroup, Box, Divider } from '@talos/kyoko';
import type { PropsWithChildren } from 'react';

export function MobileBlotter({ title, children }: PropsWithChildren<{ title: string }>) {
  return (
    <AccordionGroup>
      <Box p="spacingComfortable" color="colorTextImportant">
        {title}
      </Box>
      <Divider />
      {children}
    </AccordionGroup>
  );
}
