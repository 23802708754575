import { DEFAULT_MAX_ROWS, IconName, WL_VIEW_BLOTTERS_TRANSFERS, WL_VIEW_SETTINGS } from '@talos/kyoko';
import { noop } from 'lodash';
import { CreditExposuresBlotter } from '../../components/Blotters/CreditExposuresBlotter';
import { MobileBlotter } from '../../components/Blotters/MobileBlotter';
import { OrderHistoryBlotter } from '../../components/Blotters/OrderHistoryBlotter';
import { TradesBlotter } from '../../components/Blotters/TradesBlotter';
import { TransfersBlotter } from '../../components/Blotters/TransfersBlotter';
import { CandleSticksWithSymbol } from '../../components/Candlesticks/CandleSticksWithSymbol';
import { OrderCards } from '../../components/OrderCards';
import { OrderForms } from '../../components/OrderForms';
import { RFQOrderForm } from '../../components/OrderForms/RFQForm';
import { Portfolio } from '../../components/Portfolio';
import { Settings } from '../../components/Settings';
import { VolumeLadder } from '../../components/VolumeLadder';
import { MobileWatchList } from '../../components/WatchList/MobileWatchList';
import { ScreenOrientationEnum } from '../types';

export const WatchListScreen = {
  component: <MobileWatchList securityRoute="/chart" />,
  label: 'Watchlist',
  path: '/',
  icon: IconName.ViewList,
  orientation: ScreenOrientationEnum.portrait,
};

export const OrderFormsScreen = {
  component: <OrderForms />,
  label: 'Trade',
  path: '/trade',
  icon: IconName.TrendUp,
  orientation: ScreenOrientationEnum.portrait,
};

export const RFQFormScreen = {
  component: <RFQOrderForm />,
  label: 'Trade',
  path: '/trade',
  icon: IconName.TrendUp,
  orientation: ScreenOrientationEnum.portrait,
};

export const OrderCardsScreen = {
  component: <OrderCards />,
  label: 'Orders',
  path: '/orders',
  icon: IconName.Collection,
};

export const PortfolioScreen = {
  component: <Portfolio />,
  label: 'Portfolio',
  path: '/portfolio',
  icon: IconName.ChartDonut,
};

export const TradesScreen = {
  component: (
    <MobileBlotter title="Trades">
      <TradesBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'Trades',
  path: '/trades',
  icon: IconName.DocumentText,
};

export const CreditScreen = {
  component: (
    <MobileBlotter title="Credit">
      <CreditExposuresBlotter renderInBackground={true} />
    </MobileBlotter>
  ),
  label: 'Credit',
  path: '/credit',
  icon: IconName.CurrencyDollar,
};

export const TransfersScreen = {
  component: (
    <MobileBlotter title="Transfers">
      <TransfersBlotter renderInBackground={true} maxRows={DEFAULT_MAX_ROWS} />
    </MobileBlotter>
  ),
  label: 'Transfers',
  path: '/transfers',
  icon: IconName.ArrowLeftRight,
  requiredPermissions: [WL_VIEW_BLOTTERS_TRANSFERS],
  requiredConfigs: ['enableBalanceTransactions'],
};

export const OrdersScreen = {
  component: (
    <MobileBlotter title="Orders">
      <OrderHistoryBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'Orders',
  path: '/orders',
  icon: IconName.DocumentText,
};

export const SettingsScreen = {
  component: <Settings />,
  label: 'Settings',
  path: '/settings',
  icon: IconName.Cog,
  requiredPermissions: [WL_VIEW_SETTINGS],
};

export const LadderScreen = {
  component: <VolumeLadder />,
  label: 'Ladder',
  path: '/ladder',
  icon: IconName.Filter,
  orientation: ScreenOrientationEnum.portrait,
};
export const ChartScreen = {
  component: <CandleSticksWithSymbol />,
  label: 'Chart',
  path: '/chart',
  icon: IconName.PresentationChartLine,
};
