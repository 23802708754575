import styled from 'styled-components';

import {
  borderCss,
  CARD_HEIGHT,
  COMPACT_CARD_HEIGHT,
  IconButton,
  MarketDataCard as MarketDataCardComponent,
  VolumeLadderMarketViewWrapper,
  VolumeLadderStepsWrapper,
} from '@talos/kyoko';

// Padding made intentionally specific for the size of the Reorder icon being used for this
export const DragHandle = styled(IconButton)`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export const MarketDataCard = styled(MarketDataCardComponent)<{
  isDragged: boolean;
  isExpanded: boolean;
}>`
  min-height: ${({ isExpanded }) => (isExpanded ? CARD_HEIGHT : COMPACT_CARD_HEIGHT)}px;
  width: auto;
  ${({ theme }) => borderCss(theme.borderRadiusContent)};
  border: 1px solid ${({ theme }) => theme.borderColorContent};
  backdrop-filter: ${({ theme }) => theme.backdropFilterContent};
  box-shadow: ${({ theme }) => theme.boxShadowContent};
  ${({ isDragged, theme }) =>
    isDragged &&
    `
    outline: 1px solid ${theme.colors.blue.default};
    outline-offset: -1px;
    box-shadow: 0px ${theme.spacingLarge}px 40px 0px rgba(0, 0, 0, 0.40);
  `}

  .delete {
    opacity: 0;
    transition: opacity 0.2s;
    color: ${({ theme }) => theme.colors.red.lighten};
  }

  &:hover {
    .delete {
      opacity: 1;
    }
  }

  ${VolumeLadderMarketViewWrapper} {
    min-height: 106px;
  }

  ${VolumeLadderStepsWrapper} {
    min-height: 190px;
  }
`;

export const FloatingDescription = styled.span`
  color: ${({ theme }) => theme.colorTextSubtle};
  position: absolute;
  top: 2.6rem;
`;
