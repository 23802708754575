import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { writeCssVars } from './cssVars';
import './fonts/helixicons/helixicons.css';

export const BaseStyle = createGlobalStyle`
${({ theme }) => theme.fontFace}

${normalize()};

* {
  box-sizing: border-box;

}


html,
body {
  width: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.colorTextDefault};
  font-family: ${({ theme }) => theme.fontFamily};
  background: ${({ theme }) => theme.backgroundBody};
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: calc(100% + env(safe-area-inset-top));
}

body {
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;

  @media screen and (max-width: 768px) {
    position: fixed;
  }
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: ${({ theme }) => theme.scrollbarSize}px;
  height: ${({ theme }) => theme.scrollbarSize}px;
}

*:hover::-webkit-scrollbar {
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background-color: ${({ theme }) => theme.backgroundScrollbar};
}

*::-webkit-scrollbar-thumb {
  transition: background-color 200ms ease;
  background-color: ${({ theme }) => theme.colorScrollbar};
  border-radius: ${({ theme }) => theme.borderRadiusScrollbar}px;
}

*:hover, .ag-root:hover .ag-body-horizontal-scroll-viewport, .ag-root:hover .ag-body-vertical-scroll-viewport {
  &::-webkit-scrollbar-thumb {
    transition: background-color 200ms ease;
    background-color: ${({ theme }) => theme.colorScrollbarHover};
  }
}

*::-webkit-scrollbar-thumb {
  border-radius: ${({ theme }) => theme.borderRadiusScrollbar}px;
}

*::-webkit-scrollbar-button {
  display:none;
}

&::-webkit-scrollbar-corner {
  background: transparent;
}

h1, h2, h3, h4 {
  color: ${({ theme }) => theme.colorTextImportant};
  font-weight: ${({ theme }) => theme.fontWeightRegular};
}

a {
  color: ${({ theme }) => theme.colorTextImportant};
}

:root {
  ${({ theme }) => writeCssVars({ theme, blacklist: ['fontUrl', 'name'] })}
}
`;

export const GlobalStyle = createGlobalStyle`
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
`;
