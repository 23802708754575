import { hsla, lighten } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  gray: {
    main: '#FFFFFF',
    '000': '#F1F1F1',
    '010': '#F8F8F8',
    '020': '#ececec',
    '030': hsla(240, 0.12, 0.91, 1),
    '040': hsla(240, 0.06, 0.85, 1),
    '050': hsla(240, 0.06, 0.81, 1),
    '060': hsla(240, 0.06, 0.77, 1),
    '070': hsla(240, 0.06, 0.73, 1),
    '080': '#193843',
    '090': '#031B23',
    '100': '#031B23',
  },

  primary: {
    mute: setAlpha(0.1, '#184B87'),
    dim: setAlpha(0.4, '#184B87'),
    default: '#184B87',
    lighten: lighten(0.1, '#3C85DD'),
  },

  green: {
    mute: setAlpha(0.1, '#00D64A'),
    dim: setAlpha(0.4, '#00D64A'),
    default: '#00D64A',
    lighten: lighten(0.1, '#00D64A'),
  },

  red: {
    mute: setAlpha(0.1, '#993131'),
    dim: setAlpha(0.4, '#993131'),
    default: '#993131',
    lighten: '#EC5B5B',
  },

  yellow: {
    mute: setAlpha(0.1, '#F2D264'),
    dim: setAlpha(0.4, '#F2D264'),
    default: '#F2D264',
    lighten: lighten(0.1, '#F2D264'),
  },

  blue: {
    mute: setAlpha(0.1, '#4AA4FF'),
    dim: setAlpha(0.4, '#4AA4FF'),
    default: '#4AA4FF',
    lighten: lighten(0.1, '#4AA4FF'),
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

export const LightWintermute2Theme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightWM2',

  spacingLayout: 8,
  backgroundHeader: setAlpha(0.75, colors.gray.main),
  borderColorHeader: 'transparent',
  backgroundContent: setAlpha(0.75, colors.gray.main),
  backgroundInput: colors.gray['020'],
  backgroundSelect: colors.gray['020'],
  backgroundSelectHover: colors.gray['020'],
  backgroundSelectFocus: colors.gray['020'],
  backgroundSelectInvalid: colors.gray['020'],
  backgroundSelectDisabled: colors.gray['020'],
  backgroundSelectReadOnly: colors.gray['020'],

  chamferMultiplier: 1,
  borderRadiusContent: 12,
  buttonGroupSharedBackground: colors.gray['030'],
  backgroundMutedButton: 'transparent',
  colorTextMutedButton: colors.gray['090'],
});
