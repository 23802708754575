import { Box, Flex, NavButton, ThemeTypes, isAdjustableColor, setAlpha } from '@talos/kyoko';
import styled from 'styled-components';

export const MobileLayoutWrapper = styled(Flex)<{ isPortrait: boolean }>`
  padding: ${({ theme, isPortrait }) =>
    !isPortrait
      ? `0 calc(${theme.spacingComfortable}px + env(safe-area-inset-right)) 0 calc(${theme.spacingComfortable}px + env(safe-area-inset-left))`
      : ''};
`;

export const Menu = styled(Flex)<{ isOpen: boolean }>`
  padding: ${({ theme }) =>
    `calc(${theme.spacingComfortable}px + env(safe-area-inset-top)) calc(${theme.spacingComfortable}px + env(safe-area-inset-right)) ${theme.spacingComfortable}px calc(${theme.spacingComfortable}px + env(safe-area-inset-left))`};
  left: 0;
  top: 0;
  bottom: 0;
  width: 80%;
  flex-direction: column;
  background: ${({ theme }) => theme.backgroundSidebar};
  box-shadow: ${({ theme, isOpen }) => (isOpen ? `0 0 25px ${theme.backgroundBody}` : 'none')};
  z-index: 4;
  position: absolute;
  transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '-100%')});
  transition: transform 300ms ease-out;
`;
Menu.defaultProps = {
  p: 'spacingComfortable',
};

export const MenuItem = styled(NavButton).attrs(attrs => ({
  ...attrs,
  ghost: true,
}))`
  width: 100%;
  font-weight: normal;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacingDefault}px;
  padding: ${({ theme }) => theme.spacingDefault}px;
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
`;

export const Overlay = styled(Box)<{ hidden: boolean }>`
  inset: 0;
  background: ${({ theme }) =>
    isAdjustableColor(theme.backgroundBody)
      ? setAlpha(0.1, theme.backgroundBody)
      : theme.type === ThemeTypes.dark
      ? theme.colors.black.dim
      : theme.colors.white.dim};
  backdrop-filter: blur(${({ hidden }) => (hidden ? '0px' : '10px')});
  position: absolute;
  z-index: 3;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: opacity 5000ms ease, backdrop-filter 5000ms ease;
  pointer-events: ${({ hidden }) => (hidden ? 'none' : 'all')};
`;

export const InputsAreSizedCorrectly = styled(Flex)`
  flex: 1;
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }
`;
