import { borderCss, Box, Flex, ResizerPlacement, Z_INDEX } from '@talos/kyoko';
import styled from 'styled-components';

export const LayoutWrapper = styled(Flex)<{ maxWidth?: number }>`
  height: 100%;
  width: 100%;
  align-items: initial;
  justify-content: initial;
`;

export const PartsWrapper = styled(Box)<{
  column?: string;
  columns: string;
  row?: string;
  rows: string;
  height?: number;
  maxWidth?: number;
}>`
  display: grid;
  flex: 1 0 0;
  ${({ column }) => `grid-column: ${column};`}
  ${({ row }) => `grid-row: ${row};`}
  gap: ${({ theme }) => theme.spacingLayout}px;
  grid-template-columns: ${({ columns }) => columns};
  grid-template-rows: ${({ rows }) => rows};
  overflow: hidden;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
  justify-content: center;
  background: ${({ theme }) => theme.backgroundLayoutWrapper};
  padding: ${({ theme }) => theme.spacingLayoutWrapper}px;
  ${({ theme }) => borderCss(theme.borderRadiusLayoutWrapper)};
`;
PartsWrapper.defaultProps = {
  mx: 'auto',
  w: '100%',
};

type PartProps = { column: string; row: string; width?: number; height?: number };

export const PartWrapper = styled(Box)<PartProps>`
  grid-column: ${({ column }) => column};
  grid-row: ${({ row }) => row};
  width: ${({ width }) => (width != null ? `${width}px` : '100%')};
  height: ${({ height }) => (height != null ? `${height}px` : '100%')};
  ${({ theme }) => borderCss(theme.borderRadiusContent)};
`;
PartWrapper.defaultProps = {
  border: 'solid 1px',
  borderColor: 'borderColorContent',
  background: 'backgroundContent',
  borderRadius: 'borderRadiusContent',
  boxShadow: 'boxShadowContent',
  position: 'relative',
  transition: 'width 200ms ease',
  backdropFilter: 'backdropFilterContent',
};

export const PartContent = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
PartContent.defaultProps = {
  borderRadius: 'borderRadiusContent',
};

export const MouseEventCapture = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.popover};
`;

export const SectionHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacingMedium}px`};
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
`;

export const HorizontalHandle = styled.div<{ placement: ResizerPlacement.LEFT | ResizerPlacement.RIGHT }>`
  position: absolute;
  ${({ theme, placement }) =>
    placement === ResizerPlacement.RIGHT
      ? `right: -${theme.spacingLayout + 1}px;`
      : `left: -${theme.spacingLayout + 1}px;`}
  padding: 0 2px;
  cursor: ew-resize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    border-left: solid 1px ${({ theme }) => theme.backgroundResizeHandle};
    border-right: solid 1px ${({ theme }) => theme.backgroundResizeHandle};
    transition: border-color 200ms ease;
    width: 3px;
    height: ${({ theme }) => theme.baseSize * 3}px;
  }
  &:hover {
    background: ${({ theme }) => theme.backgroundContentHover};
    > div {
      border-color: ${({ theme }) => theme.backgroundResizeHandleHover};
    }
  }

  width: ${({ theme }) => theme.spacingLayout}px;
  height: 100%;
`;

export const VerticalHandle = styled.div<{ placement: ResizerPlacement.TOP | ResizerPlacement.BOTTOM }>`
  position: absolute;
  ${({ theme, placement }) =>
    placement === ResizerPlacement.TOP
      ? `top: -${theme.spacingLayout + 1}px;`
      : `bottom: -${theme.spacingLayout + 1}px;`}
  padding: 2px 0;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    border-top: solid 1px ${({ theme }) => theme.backgroundResizeHandle};
    border-bottom: solid 1px ${({ theme }) => theme.backgroundResizeHandle};
    transition: border-color 200ms ease;
    height: 3px;
    width: ${({ theme }) => theme.baseSize * 3}px;
  }
  &:hover {
    background: ${({ theme }) => theme.backgroundContentHover};
    > div {
      border-color: ${({ theme }) => theme.backgroundResizeHandleHover};
    }
  }

  height: ${({ theme }) => theme.spacingLayout}px;
  width: 100%;
`;
